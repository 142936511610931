@font-face{
    font-family: AzoSans;
    src: url('../fonts/AzoSans/Azo_Sans_Uber_Regular.otf');
    font-display: swap;  
}

@font-face{
    font-family: AzoSans;
    src: url('../fonts/AzoSans/Azo_Sans_Bold.otf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: AzoSans;
    src: url('../fonts/AzoSans/Azo_Sans_Medium.otf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: AzoSans;
    src: url('../fonts/AzoSans/Azo_Sans_Bold.otf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: AzoSans;
    src: url('../fonts/AzoSans/Azo_Sans_Light.otf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: AzoSans;
    src: url('../fonts/AzoSans/Azo_Sans_Regular.otf');
    font-weight: 400;
    font-display: swap;
}

@font-face{
    font-family: AzoSans;
    src: url('../fonts/AzoSans/Azo_Sans_Bold.otf');
    font-weight: 700;
    font-display: swap;
}


@font-face{
    font-family: AzoSans;
    src: url('../fonts/AzoSans/Azo_Sans_Bold.otf');
    font-weight: 900;
    font-display: swap;
}

@font-face{
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-Bold.ttf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-Regular.ttf');
    font-weight: 400;
    font-display: swap;
}

@font-face{
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}


@font-face{
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-ExtraBold.ttf');
    font-weight: 900;
    font-display: swap;
}

@font-face{
    font-family: Adistria;
    src: url('../fonts/Adistria/Adistria.ttf');
    font-weight: 400;
    font-display: swap;
}

:root{
    --primary-ss-color:#b78b4c; 
    --secondary-ss-color: #1f1f1f;
}

*{
    padding: 0;
    margin: 0;
}

body{
    font-family: AzoSans, sans-serif;
    background-image: url(../images/800home/Pattern.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg{
    background-image: url(../images/800home/Pattern.jpg) !important;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}

.text-banner{
    font-family: banner, sans-serif;
}

.text-azosans{
    font-family: AzoSans, sans-serif;
}

.text-adistria{
    font-family: Adistria, sans-serif !important;
}

.text-ss-primary{
    color: var(--primary-ss-color);
}
.text-ss-secondary{
    color: var(--secondary-ss-color);
}
.text-ss-black{
    color: var(--black-color);
}

.bg-ss-primary{
    background-color: var(--primary-ss-color);
}
.bg-ss-secondary{
    background-color: var(--secondary-ss-color);
}

.bg-ssl-primary{
    background-color: var(--primary-ssl-color);
}
.bg-ssl-secondary{
    background-color: var(--secondary-ssl-color);
}
.bg-secondary-ssl-color{
    background-color: var(--secondary-ssl-color);
}

.secondary-ssl-color{
    color: var(--secondary-ssl-color);
}

.w80px{
    width: 80px;
}

.home-services-img{
    /* width: 100%; */
    width: 100px;
    max-width: 200px;
}

.border-ss-white{
    border-top: 2px solid #fff;
    border: none;
    height: 2px;
    opacity: 1;
    color: #fff;
    background-color: #fff;

}

.fs-ss-92{
    font-size: 92px;
}

.fs-ss-68{
    font-size: 68px;
}

.fs-ss-58{
    font-size:58px;
}

.fs-ss-56{
    font-size:56px;
}
.fs-ss-52{
    font-size:52px;
}

.fs-ss-48{
    font-size: 48px;
}

.fs-ss-40{
    font-size: 40px;
}
.fs-ss-35{
    font-size: 35px;
}

.fs-ss-32{
    font-size: 32px;
}

.fs-ss-30{
    font-size: 30px;
}

.fs-ss-28{
    font-size: 28px;
}
.fs-ss-26{
    font-size: 26px;
}
.fs-ss-24{
    font-size: 24px;
}
.fs-ss-22{
    font-size: 22px;
}

.fs-ss-20{
    font-size: 20px;
}

.fs-ss-18{
    font-size: 18px;
}

.fs-ss-16{
    font-size: 16px;
}
.fs-ss-17{
    font-size: 17px;
}
.fs-ss-15{
    font-size: 15px;
}

.fs-ss-14{
    font-size: 14px;
}

.logo-img{
    height:60px;
}

.container-ss{
    max-width: 90%;
    padding-right: 5%;
    padding-left: 5%;
    margin-right: auto;
    margin-left: auto;
}

.container-ss2{
    max-width: 86%;
    padding-right: 7%;
    padding-left: 7%;
    margin-right: auto;
    margin-left: auto;
}

#website-nav .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

#website-nav .navbar-toggler{
    border: none;
    border-radius: 0;
    background-color: #fff;
}

#website-nav .nav-link{
    color: #000;
    margin-right: 16px;
    padding-top: 0;
    padding-bottom: 0;
}


#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}

#website-nav .sub-nav-menu:hover .sub-sub-child-menu .dropdown-menu {
    display: none;
}

#website-nav .sub-nav-menu .dropdown-menu {
    margin-top: 0;
}
#website-nav .sub-child-menu:hover .dropdown-menu {
    display: block;
    visibility: visible;
    margin-top: -10px;
}

#website-nav .sub-child-menu .sub-sub-child-menu:hover .dropdown-menu{
    display: block;
    visibility: visible;
}

#website-nav .active{
    color: var(--secondary-ss-color);
}

#website-nav .dropdown-toggle{
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}

.dropdown-item.active, .dropdown-item:active{
    background-color: var(--primary-ss-color);
}

#website-nav .sub-child-menu .dropdown-menu {
    /* display: none; */
    visibility: hidden;
    margin-top: 0;
}
#website-nav .sub-child-menu .dropdown-menu a{
    padding: 5px 10px;
    font-size: 15px;

}
#website-nav .sub-child-menu .dropdown-menu{
    width: 240px;
}
#website-nav .dropdown-item{
    cursor: pointer;
}
.navbar-offcanvas{
    /* background-color: var(--primary-ss-color);
    color: #fff; */
    padding: 0rem;
}

.take-control-bg{
    background-color: var(--primary-ssl-color) ;
    /* background color to -10vw */
    background-position: 0 -10vw;
}
.minusBg10 {
    margin-top: -100px;
}

.minusBg12 {
    margin-top: -120px;
}

.lh-1-2{
    line-height: 1.2;
}


.lb{
    margin-top: 30px;
    margin-left: 5px;
    padding-left: 30px;
    border-left: 4px solid #004bda;
}



.slick-slide-title {
    color: #fff;
    padding: 10px;
    position: absolute;
    /* text-transform: ; */
    left: 8vw;
    font-size: 3.2vw;
    font-family: OpenSauceOne;
    font-weight: 700;
    top: 10%;
    width: 80%;
    line-height: 1.1;
    letter-spacing: 2px;
  }
  .slick-slide-title-desc {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 8vw;
    font-size: 3.5vw;
    font-weight: 500;
    top: 48%;
    width: 80%;
  }
  .slick-slide-btn{
    position: absolute;
    left: 9vw;
    font-weight: 500;
    top: 67%;
    width: 80%;
  }

  .slick-slide-button{
    font-size: 1.4vw;
  }
  


.bg-contact-opacity{
    background-color: #181818;
    opacity: 0.95;
}

.text-white{
    color: #ffffff;
}
.banner-section{
    position: relative;
}
.about-col-bg-white{
    background-color: #ffffff;
    border-radius: 10px;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}
.about-col-b{
    border: 3px solid #004bda;
    border-radius: 10px;
    align-content: center;
}

.w80px {
    width: 80px;
}

.border-header{
    border-bottom: 4px solid var(--secondary-ss-color) !important;
}

.accordion-button{
    padding: 10px 20px !important;
}
.accordion-button:focus{
    box-shadow: 0;
}
.accordion-button:not(.collapsed){
    background-color: transparent !important;

}
.accordion-button:focus{
    box-shadow: none !important;
}
.accordion-item {
    border-left: 0 !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    border-bottom: 1px solid #cfcfcf !important;

}

.accordion{
    --bs-accordion-bg: transparent !important ;
    --bs-accordion-color: white !important ;
    --bs-accordion-btn-icon: url(../images/Plus.png) !important ; 
    --bs-accordion-btn-active-icon: url(../images/Minus.png) !important ; 
    --bs-accordion-body-padding-x: 0rem !important ; 
    --bs-accordion-body-padding-y: 0rem ;
    --bs-accordion-border-color: #cfcfcf00 ;
}

.accordion-button {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.splash-screen {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f2f3f8;
    color: #5E6278;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
}
  
.splash-screen span {
color: #5E6278;
transition: none !important;
text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
}

.splash-screen img {
margin-left: calc(100vw - 100%);
margin-bottom: 30px;
height: 38px !important;
}

.slick-slide-image {
    max-width: 100%;
    height: auto;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  }
  .slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
  }

  .slick-slide-content{
    color: #fff;
    position: absolute;
    left: 7vw;
    top: 30%;
    width: 45%;

}


.slider-wrapper {
    width: 100%;

    margin: auto;
  }

  .btn-ss-white{
    background-color: #fff;
    color: black;
    border: 0;
    border-radius: 0;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
  }

.btn-ss-white:hover{
    background-color: rgb(238, 238, 238);
    color: black;
    border: 0;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
}
.btn-ss-white:focus{
    background-color: rgb(238, 238, 238) !important;
    color: black !important;
}
.home-banner .row{
    margin: 0;
}

.spacer-ss-15{
    height: 15px;
}

.spacer-ss-25{
    height: 25px;
}

.spacer-ss-37{
    height: 37px;
}

.footer-links-section{
    padding-top: 200px;
    padding-bottom: 40px;
}
.border-ss-secondary-footer{
    background-color: var(--secondary-ss-color);
    border: none;
    color: var(--secondary-ss-color);
    height: 3px;
    opacity: 1;
    width: 100px;
}
.border-gray2{
    border-top: 1px solid #ffffff40
}

  .rounded30{
    border-radius: 30px;
  }

  .rounded20{
    border-radius: 20px;
  }

  .rounded10{
    border-radius: 10px;
  }

  .rounded6{
    border-radius: 6px;
  }

  .btn-ss-outline-white{
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    /* height: 3em; */
    min-width: 120px;
    font-family: work-sans;
    font-weight: 600 !important;
    border: 1px solid #fff;

}
.btn-ss-primary{
    background-color: var(--primary-ss-color);
    color: #fff;
    cursor: pointer;
}

.btn-ss-secondary{
    background-color: var(--secondary-ss-color);
    color: #fff;
    cursor: pointer;
}


.btn-ss-primary-banner{
    background-color: var(--primary-ss-color);
    color: #fff;
    cursor: pointer;
    /* padding: 2vw 5vw; */
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
}

.btn-ss-primary-banner:hover{
    background-color: var(--primary-ss-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
    padding: 1vw 2vw;
}

.btn-ss-white-banner{
    background-color: #fff;
    color: var(--primary-ss-color);
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
    border-radius: 30px;
}

.btn-ss-white-banner:hover{
    background-color: #fdfdfd;
    opacity: 0.94;
    color: var(--primary-ss-color);
    cursor: pointer;
    padding: 1vw 2vw;
}
.home-background-banner{
    /* background-image: url('../images/home/banner.webp'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 82vh;
    width: 100%;
    position: relative;
}
.home-banner-content{
    position: absolute;
    top: 15%;
    width: 55%;
    left: 13%;
}
.home-banner-heading{
    letter-spacing: 3px;
}
.home-banner-btn{
    font-size: 18px;
    border-radius: 30px !important;
}
.btn-ss-primary:hover{
    background-color: var(--primary-ss-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.btn-ss-secondary:hover{
    background-color: var(--secondary-ss-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.btn-ss-secondary:focus{
    background-color: var(--secondary-ss-color) !important;
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.shadow-ss-1{
    box-shadow: 2px 3px 3px 2px #dadada !important;
}

.shadow-ss-2{
    box-shadow: 2px 5px 5px 4px #dadada !important
}

.shadow-ss-1-blogs{
    box-shadow: 0.1px 0.1px 8px 0.1px #dadada !important;
}

.border-zk-card-bottom{
    border-bottom: 5px solid var(--primary-ss-color);
    border-left: 0;
    border-right: 0;
    border-top: 0;
}


.padding10xem{
    padding: 10em 0;
}

.padding7xem{
    padding: 7em 0;
}

.padding5xem{
    padding: 5em 0;
}

.padding2-2xem{
    padding: 2.2em 0;
}
.padding3xem{
    padding: 3em 0;
}


.navbar-nav .dropdown-menu {
    background: #fdfdfd;
    border: rgb(255, 255, 255);
    box-shadow: #dadada 0px 0px 2px 0px;
    border-radius: 0;
    border-top: 2px solid var(--primary-ss-color);
    position: static;
    width: 100%;
}

#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}
#website-nav .sub-nav-menu .dropdown-menu {
    width: max-content;
    margin-top: 0;
}

.emr_featured_col{
    /* middle */
    min-height: 55px;

}

.card-stretch{
    height: calc(100% - 1px);
}

.pagination-controls{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-controls button{
    background-color: #fff;
    border: 1px solid #d3d3d3;
    padding: 10px 5px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
}

.pagination-controls button.active{
    background-color: var(--primary-ss-color);
    color: #fff;
    border: 1px solid var(--primary-ss-color);
}

.home-mobile-pic{
    width: 30%;
}
.page-banner-title{
    color: #fff;
    /* padding: 10px; */
    position: absolute;
    font-family: OpenSauceOne;
    font-weight: bolder;
    font-size: 3vw;
    top: 40%;
    letter-spacing: 1px;
    color: #000;
    /* width: 80%; */
  }
  .page-banner-text1{
    font-size: 2vw;
  }
  .page-banner-desc{
    font-size: 1.4vw;
    font-weight: 400;
    margin-top: 10px;
    /* margin-top: 1px; */




  }

.navbar-nav .dropdown-menu {
    box-shadow: 0;
    border-radius: 0;
    border: 0;
    padding: 10px;
}

.digital-marketing-icon{
    width: 50% !important;
}
.about-weuse-section{
    min-height: 175px !important;
}

.about-ourphilosophy{
    width: 130px;
    height: 120px;
}
.home-healthimages{
    width: 40%;
}
.home-practice-img{
    width: 100%;
}

.home-practice-animation{
    width: 50%;
}
.whoweserve-healthimages{
    width: 25%;
} 

.about-connection-sec{
    min-height: 460px;
}
.marginTopvitals1{
    margin-top: -180px;
}

.paddingTopvitals2{
    padding-top: 190px;
}

.p-top{
    padding-top: 100px;
}

.p-bottom{
    padding-bottom: 100px;
}

.section1{
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
}

.section2{
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
}



.table-bordered{
    border: 1px solid #d3d3d3 !important;
}

.border-bottom{
    border-bottom: 1px solid #f51a44 !important;  
}

.shadow-os{
   box-shadow: -3px 3px 4px 1px rgb(0 0 0 / 30%);
}

.icon-size{
    width: 51px;
}


.fw-900{
    font-weight: 900;
}

.fw-800{
    font-weight: 800;
}

.fw-700{
    font-weight: 700;
}


.fw-600{
    font-weight: 600;
}

.fw-500{
    font-weight: 500;
}

.fw-400{
    font-weight: 400;
}

.lh-44{
    line-height: 44px;
}

.section1 .row{
    margin-right: 0;
    margin-left: 0;
}

.btn-ss-white-secondary{
    background-color: #fff;
    border: 3px solid var(--primary-ss-color);
}
.btn-ss-white-secondary:hover{
    background-color: var(--secondary-ss-color);
    color: #fff;
}

.ls-lg-ss-aim{
    letter-spacing: 16px;
}

.provider-img{
    width: 70%;
}

.provider-bg{
    background: linear-gradient(var(--primary-ss-color) 0 50%, white 50% 100%);
    border-radius: 6%;
    padding: 40px 10px 20px 10px;
    box-shadow: 2px 3px 3px 2px #dadada !important;
    margin: 0;
}
.provider-pic-w{
    max-width: 65%;
}
.provider-sec-bg{
    background-color: #F2F5F7;
}

.provider-col-bg{
    background: white;
    border-radius: 0%;
    padding: 20px 10px 5px 10px;
    box-shadow: 1px 1px 2px 2px #dadada;
    margin: 0;
}
.pa{
    position: absolute;
    top: -450px;
}

.home-insurance-img{
    width: 75%;
}

.footer-bg{
    background-color: #d3d3d3;
}

.border-zk-secondary-footer{
    width: 100px;
    height: 3px;
    background-color: var(--secondary-ss-color);
    margin-top: 10px;
    opacity: 1;
    border: var(--secondary-ss-color);
}

.footer-logo{
    width: 250px;
}
.supernova{
    background-color: #ebebff00 !important;
}
.no-row-space .row{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

.bg-none{
    background-color: rgb(0,0,0,0.1);
}

.medicatl-texting ::marker{
    color: var(--secondary-ss-color);
    font-size: 25px;
}


@media screen and (max-width: 1281px) {
    .home-marketing-section-content .fs-ss-16{
        font-size: 15px;
    }

    #website-nav .nav-link{
        margin-right: 10px;
    }

    #website-nav .fs-ss-16{
        font-size: 15px;
    }

    
}

@media screen and (min-width: 992px ) and (max-width: 1200px ) {
    #navbar-bottom .fs-ss-16{
        font-size: 14px !important;
    }
}

@media screen and (max-width: 1150px){

}

@media screen and (max-width: 1050px){
    #website-responsive .fs-ss-92{
        font-size: 55px;
    }

    #website-responsive .fs-ss-68{
        font-size: 45px;
    }

    .ls-lg-ss-aim{
        letter-spacing: 10px;
    }

}

@media screen and (max-width: 991px) {
    .w-50{
        width: 100% !important;
    }
    
    .bg-home-banner{
        height: 51vh !important;
    }
    .w-content{
        background-color: white;
        padding-top: 10px ;
        padding-bottom: 10px;
        border-radius: 0px !important;
        color: black !important;
    }

    .container-ss{
        max-width: 96%;
        padding-right: 2%;
        padding-left: 2%;
        margin-right: auto;
        margin-left: auto;
    }
    .provider-bg{
        background: linear-gradient(var(--primary-ss-color) 0 30%, white 70% 100%);
    }
    .home-services-img{
        width: 120px;
    }
    .home-mobile-pic{
        width: 30%;
    }
    .logo-img{
        height:50px;
    }
    #website-responsive .minusBg10, #website-responsive .minusBg12 {
        margin-top: 0;
    }

    .home-insurance-img{
        width: 60%;
    }

    #website-responsive .fs-ss-92{
        font-size: 55px;
    }
    #website-responsive .service-healthcare-bg  .fs-ss-92{
        font-size: 45px;
    }

    #home-we-aim .fs-ss-92{
        font-size: 45px;
    }

    #website-responsive .fs-ss-68{
        font-size: 45px;
    }

    #website-responsive .fs-ss-56, #website-responsive .fs-ss-58{
        font-size:40px;
    }

    #website-responsive .fs-ss-52{
        font-size:38px;
    }


    #website-responsive .fs-ss-48{
        font-size:36px;
    }

    #website-responsive .fs-ss-40{
        font-size:32px;
    }

    #website-responsive .fs-ss-35{
        font-size:28px;
    }

    #website-responsive .padding10xem, #website-responsive .padding7xem, #website-responsive .padding5xem{
        padding: 3em 0;
    }

    #website-responsive .padding3xem{
        padding: 2em 0;
    }

    /* .btn-ss-white{
        padding: 0;
    }
    .btn-ss-white:hover{
        padding: 0;
    }
    .btn-ss-white button{
        padding: 0;
    } */
    .page-banner-title{
        top:40%;
    }
    .about-weuse-section1, .about-weuse-section2, .about-weuse-section3, .about-weuse-section4{
        width: 100%;
        height: unset;
        min-height: unset;
        display: block;
        padding: 40px 30px !important;
        font-weight: 600;
    }
    .navbar{
        padding: unset;
    }
    
    /* .home-banner-content{
        position: absolute;
        top: 15%;
        width: 50%;
        left: 3%;
    } */
}

@media screen and (min-width: 992px) {
    #cv .form-control{
        padding-left: 22% !important;
    }
}

@media screen and (max-width: 768px) {
    .fs-ss-blog{
        padding-top: 10vh !important;
        font-size: 22px !important;
    }

    .w-blogs{
        width: 80% !important;
    }

    #cv .form-control{
        padding-left: 31% !important;
    }
    .w-blog-image{
        width: 100% !important;
    }

    #bg-middle-banner .fs-ss-22{
        font-size: 16px !important;
    }

    #social-media .fs-ss-22{
        font-size: 22px !important;
    }

    #numbers .fs-ss-40{
        font-size: 35px !important;
    }

    #home-banner .fs-home-banner{
        font-size: 30px !important ;
    }

    #home-banner .fs-sub-home-banner{
        font-size: 18px !important;
        letter-spacing: 2px !important;
    }

    .w-50{
        width: 100% !important;
    }

    .bg-1{
        background-image: url(../images/1.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 250px !important;
    }
    
    .bg-2{
        background-image: url(../images/2.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 250px !important;
    }

    .banner-title{
        font-size: 30px !important;
        padding-top: 13.5vh !important;
    }

    .banner-title-sell-property{
        font-size: 20px !important;
        padding-top: 11.7vh !important;    }

    .common-banner{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 30vh !important;
    }

    .sticky-top{
        position: none !important;
        top: none !important ;
    }

    .width-desktop-mobile{
        width: 95% !important;
    }

    .rounded-start{
        border-radius: 50px !important ;
    }
    
    .slick-prev{
        left: 0% !important; 
        z-index: 1;
        background-color: #000 !important;
        border-radius: 100px;
    }
    
    .slick-next{
        right: 0% !important; 
        z-index: 1;
        background-color: #000 !important;
        border-radius: 100px;
    }

    #testimonials .w-90{
        padding-left: 40px !important;
        padding-right: 40px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    #numbers .border-1{
        border: none !important;
    }

   #common-banner .fs-home-banner{
        font-size: 22px !important;
    }
    
    #common-banner .fs-sub-home-banner{
        font-size: 15px !important;
        letter-spacing: 2px !important;
    }

    .fs-header-link{
        font-size: 16px !important;
    }
    .header-logo{
        width: 130px !important;
    }

    .footer-logo{
        width: 170px;
        margin-bottom: 20px;
    }
    .spacer-ss-25{
        height: 15px;
    }
    .home-medical-img{
        width: 60%;
    }
    .home-billing-img{
        width: 80%;
    }
    .provider-pic-w{
        max-width: 150px;
    }
    .integrated-section-img-1, .integrated-section-img-2, .integrated-section-img-3, .integrated-section-img-4{
        background-size: cover;
        background-attachment: unset;
    }
    .review-section-img-1, .review-section-img-2, .review-section-img-3{
        background-size: cover;
        background-attachment: unset;
    }
    .appointment-section-img-1, .appointment-section-img-2, .appointment-section-img-3{
        background-size: cover;
        background-attachment: unset;
    }

    #website-responsive .minusBg10 {
        margin-top: 0;
    }

    .home-practice-img{
        width: 40%;
    }
    .home-practice-animation{
        width: 70%;
    }

    #website-responsive .fs-ss-20{
        font-size:16px;
    }
    .slick-slide-content{
        top: 35%;
        width: 64%;
        left: 2vw;
    }
    .slick-slide-title-desc{
        font-size: 2.7vw;
        font-weight: 500;
        padding: 0 10px 3px 10px;
    }

    .slick-slide-title{
        font-size: 4vw;
        padding: 0 10px;
        top:20%;
        left: 2vw;
        line-height: 27px;
    }
    .slick-slide-btn{
        top: 60%;
    }
    
    .slick-slide-button{
        font-size: 2vw;
      }

    .home-banner-btn{
        font-size: 12px !important;
    }
    .page-banner-btn{
        margin-top: 10px;
    }
    .btn-ss-white-banner{
        font-size: 13px;
        /* margin-top: 20px; */
    }
    .btn-ss-primary-banner{
        font-size: 14px;
        margin-top: 20px;
    }
    
    .digital-marketing-icon{
        width: 60% !important;
    }
    .about-weuse-section{
        min-height: 100% !important;
    }
    .home-healthimages{
        width: 30%;
    }

    .home-healthimages img{
        width: 30%;
    }
    .page-banner-title{
        font-size: 4vw;
        top: 40%;
        line-height: 5.5vw;
    }
    .page-banner-text1{
        font-size: 3vw;
    }
    .page-banner-desc{
        font-size: 3vw;
        font-weight: 500;
        display: none;
        line-height: 3vw;
    }
    .about-connection-sec{
        min-height: 100%;
    }
    .text-home-onestop{
        font-size: 24px !important; 
    }
    .about-ourphilosophy{
        width: 100px;
        height: 80px;
    }
    #reviews-lb .lb{
        margin-top: 7px;
        margin-left: 0;
        padding-left: 0;
        border-left: unset;
    }

    .marginTopvitals1{
        margin-top: -110px;
    }
    
    .paddingTopvitals2{
        padding-top: 120px;
    }

    .emr_featured_col{
        /* middle */
        min-height: 0px;
    
    }
    .footer-links-section{
        padding-bottom: 0;
    }
    .ls-lg-ss-aim{
        letter-spacing: 2px;
    }

    .home-insurance-img{
        width: 50%;
    }
    .about-timing-bg{
        background: none;
    }

    #whatisccm-comprehensive .container-ss{
        max-width: 100% !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        margin-right: auto;
        margin-left: auto;

    }
    
}
  

@media screen and (max-width: 576px) {
    .margin-custom2{
        margin-top: 0px !important;
    }

    .bg{
        background-position: bottom !important;
        background-size: contain !important; 
    }
    .position-child-location{
        bottom: 20% !important;
    }
    .fs-ss-banner-location{
        font-size: 3.7vw !important;
    }

    .bg-home-banner{
        height: 64vh !important;
    }
    
    .bg-common-banner{
        height: 40vh !important;
    }
    
    .fw--home-banner{
        font-weight: 600 !important
    }
    
    .margin-form{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    #belowbanner .fs-ss-26{
        font-size: 18px !important ;
        font-weight: 500 !important;
    }

    .lh-lgh{
        line-height: 25px !important;
    }
    .col-ss-four-boxes-footer{
        width: 92% !important;
    }
    .section1{
        min-height: 100%;
    }
    .section2{
        min-height: 100%;
    }
    .provider-img{
        width: 40%;
    }

    .slick-slide-title{
        font-size: 5vw;
        top:20%;
        line-height: 17px;
        left: 1vw;

    }

    .slick-slide-title-desc{
        top:45%;
        font-size: 4vw;
        font-weight: 500;
        
    }

    .slick-slide-title2{
        font-size: 3.7vw;
        top:20%;
        line-height: 17px;
        left: 1vw;
    }

    .slick-slide-btn{
        top: 60%;
        margin-left: 10px;
    }
    

    .slick-slide-button{
        font-size: 3.5vw;
      }

      .home-services-img{
        width:120px;
      }
    .home-mobile-pic{
        width: 50%;
    }

    #website-responsive .fs-ss-92{
        font-size: 45px;
    }

    #website-responsive .service-healthcare-bg  .fs-ss-92{
        font-size: 35px;
    }

    #home-we-aim .fs-ss-92{
        font-size: 35px;
    }

    #services-cta .fs-ss-92{
        font-size: 35px;
    }

    #website-responsive .fs-ss-68{
        font-size: 36px;
    }

    
    #website-responsive .fs-ss-56, #website-responsive .fs-ss-58{
        font-size:28px;
    }
    #website-responsive .fs-ss-52{
        font-size:41px;
    }
    
    #website-responsive .fs-ss-48{
        font-size: 24px;
    }

    #website-responsive .fs-ss-40{
        font-size: 24px;
    }

    #website-responsive .fs-ss-35{
        font-size: 22px;
    }

    #website-responsive .fs-ss-30, #website-responsive .fs-ss-28{
        font-size: 20px;
    }

    #website-responsive .fs-ss-26, #website-responsive .fs-ss-24{
        font-size: 19px;
    }

    .navbar-brand .fs-ss-26{
        font-size: 19px;
    }

    #website-responsive .fs-ss-22{
        font-size: 18px;
    }
    #website-responsive .fs-ss-18, #website-responsive .fs-ss-17{
        font-size:16px;
    }
    .about-health-section .fs-ss-30{
        font-size: 23px !important;
    }

    .container-ss, .container-ss2{
        max-width: 96%;
        padding-right: 2% !important;
        padding-left: 2% !important;
        margin-right: auto;
        margin-left: auto;
    }
    
}



@media screen and (max-width: 480px) {
    .page-banner-title{
        top:40%;
    }
}

@media screen and (max-width: 440px) {
    .page-banner-title{
        top:40%;
    }
}

@media screen and (max-width: 400px) {
    .page-banner-title{
        top:40%;
    }
}

@media screen and (max-width: 320px) {
    .page-banner-title{
        top:35%;
    }
    
}

@media screen and (max-width: 280px) {
    
}

.position-header{
    position: relative !important;
}

.position-bottom-header{
    position: absolute !important;
    bottom: -27px;
}

.col-ss-four-boxes-footer{
    width: 24%;
}

.bg-ss-secondary-light {
    background-color: rgba(201, 162, 113, 0.8);
}


.bg-grey{
    background-color: #cfcfcf;
}

.lh-lgh{
    line-height: 30px;
}

.shadow-right{
    box-shadow: 6px 0px 7px 4px rgba(128, 128, 128, 0.384);
}

.z-front-page{
    z-index: 100 !important;
}

.z-back-page{
    z-index: -100 !important;
}

.fs-ss-12{
    font-size: 12px;
}

.middle-position-relative{
    position: relative;
}

.middle-position{
    position: absolute;
    bottom: 80px;
    left: 42%;
}

.map-style{
    filter: grayscale(100%);
}


.location-position-map{
    position: relative ;
}

.location-position{
    position: absolute ;
    top: -70px;
}

.border-2-bottom-black{
    border-bottom: 2px solid black;
}

.border-ss-secondary{
    border: 1px solid #aa8332 !important;
}

.position-img1-parent{
    position: relative;
}
.position-img2-parent{
    position: relative;
}
.position-img3-parent{
    position: relative;
}
.position-img4-parent{
    position: relative;
}
.position-img5-parent{
    position: relative;
}

.position-img1-child{
    position: absolute;
    top: -80px;
    left: -120px;
}

.position-img2-child{
    position: absolute;
    right: -120px;
}

.position-img3-child{
    position: absolute;
    left: -120px;
}

.position-img4-child{
    position: absolute;
    right: -120px;
}

.position-img5-child{
    position: absolute;
    top: -10px;
    left: -120px;
}

.w-10{
    width: 10% !important;
}

.w-15{
    width: 15% !important;
}

.w-20{
    width: 20% !important;
}

.w-65{
    width: 65% !important;
}

.bg-hover-ss-primary:hover{
    background-color: #b78a4cc9 !important;
}

.text-hover-ss-primary:hover{
    color: var(--primary-ss-color) !important;
}


.position-whyus-parent{
    position: relative;
}

.position-whyus-child{
    position: absolute;
    top: -40px;
    right: -120px;
}

.pattern-plans-1{
    background-color: #252b37;
}

.hover-golden{
    color: var(--primary-ss-color) !important;
    background-color: white;
}

.hover-golden:hover{
    color: white !important;
    background-color: #404757;
}

.hover-border{
   border: 1px solid #aa8332;
   background-color: var(--primary-ss-color);
}

.hover-border:hover{
    border: 3px solid #aa8332 !important;
 }

 .hover-golden-pri{
    color: white;
    background-color: var(--secondary-ss-color);
 }

 .hover-golden-pri:hover{
    color: white;
    background-color: var(--primary-ss-color);
 }


 .hover-golden-white{
    color: white;
    background-color: var(--secondary-ss-color);
 }

 .hover-golden-white:hover{
    color: var(--primary-ss-color);
    background-color: white;
 }
.bg-pg-nmb{
    background-color: #1f2530 ;
}

.pointer-clickable{
    cursor: pointer !important;
}

.navbar-offcanvas{
    background-color: var(--secondary-ss-color);
}

.bg-light{
    background-color: #ebebeb !important;
}

.btn-close{
    background-color: white !important;
}

.shadow-os{
    box-shadow: 5px 5px 12px rgb(128, 128, 128);
}


.book-flip{
    height: 580px !important;
}

.margin-form{
    margin-left: 120px ;
    margin-right: 120px;
}

.ls-2{
    letter-spacing: 2px;
}

.form-control{
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #cfcfcf !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding-left: 2px !important;
    padding-bottom: 0px !important;
}


.form-control::placeholder{
    color: #cfcfcf60 !important;
    font-weight: 300 !important;
    font-size: 16px !important;
}

.inquiry-modal .form-control::placeholder{
    color: #11111160 !important;
    font-weight: 300 !important;
    font-size: 16px !important;
}

.form-control:focus{
    border-bottom: 1px solid var(--primary-ss-color) !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.form-select:focus{
    border: 1px solid #aa8332 !important;
    box-shadow: none !important;
    background-color: #f4f4f4 !important;
}
.inquiry-modal .PhoneInputInput{
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #cfcfcf !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding-left: 2px !important;
    padding-bottom: 0px !important;
}

.inquiry-modal .PhoneInputInput::placeholder{
    color: #cfcfcf60 !important;
    font-weight: 300 !important;
    font-size: 16px !important;
}

.inquiry-modal .PhoneInputInput{
    border: none !important;
    border-radius: 0px !important;
    /* border-bottom: 1px solid #cfcfcf !important; */
    box-shadow: none !important;
    background-color: transparent !important;
    padding-left: 2px !important;
    padding-bottom: 0px !important;
    color: #111111 !important;
}

.PhoneInputInput{
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #cfcfcf !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding-left: 2px !important;
    padding-bottom: 0px !important;
    color: #cfcfcf;
}

.PhoneInputInput:focus, .PhoneInputInput:focus-visible{
    /* border-bottom: 1px solid var(--primary-ss-color) !important; */
    border: 0px !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.PhoneInputInput:focus-visible {
    outline: none !important;
    border: none !important;
}


.fw--home-banner{
    font-weight: 500
}

.w-33{
    width: 32% !important;
}

.border-brown{
    border: 5px solid #aa8332;
}

.w-98{
    width: 98% !important;
}


.shadow-img{
    box-shadow: 3px 1px 8px rgb(167, 167, 167) ;
}


.position-parent{
    position: relative;
}

.position-child{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.position-child-location{
    position: absolute;
    bottom: 30%;
    right: 0%;
    transform: translate(0%, 0%);
}

.fs-ss-banner-location{
    font-size: 2.0vw;
}

.react-tabs ul{
    padding-left: 0px !important;
}

.w-s{
    width: 100%;
    height: 480px;
}

.slick-dots{
    bottom: -50px !important;
}

.margin-custom{
    margin-right: 60px ;
    margin-left: 60px ;
}

.margin-custom2{
    margin-top: 40px ;
}


/* 800 Homes */

.bg-home-banner{
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.bg-common-banner{
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.vh-100{
    height: 100vh;
}

.fs-header-link{
    font-size: 1.1vw;
}

.fs-home-banner{
    font-size: 4.54vw;
}

.fs-sub-home-banner{
    font-size: 1.72vw;
    letter-spacing: 4px;
}

.header-logo{
    width: 8.5vw;
}

.footer-logo{
    width: 150px;
}

.fw-300{
    font-weight: 300;
}

.fw-400{
    font-weight: 400;
}

/* .text-shadow-parent{
    color: white;
    font-size: 30px;
    position: relative;
}

.text-shadow-child{
    color: white;
    opacity: 0.05;
    font-size: 43px;
    position: absolute;
    bottom: -7px;
    left: -5px;
} */

/* .text-shadow-parent-2{
    color: var(--primary-ss-color);
    font-size: 40px;
    position: relative;
}

.text-shadow-child-2{
    z-index: -1;
    color: white;
    opacity: 0.05;
    font-size: 60px;
    position: absolute;
    bottom: -10px;
    left: 33%;
    right: auto;
} */

/* .text-shadow-parent-3{
    color: var(--primary-ss-color);
    font-size: 40px;
    position: relative;
}

.text-shadow-child-3{
    z-index: -1;
    color: white;
    opacity: 0.05;
    font-size: 60px;
    position: absolute;
    bottom: -10px;
    left: 36%;
    right: auto;
} */

.bg-footer{
    background-image: url(../images/800home/Footer.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-middle-banner{
    background-image: url(../images/800home/Img2.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-img-1{
    background-image: url(../images/800home/Img3.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-img-2{
    background-image: url(../images/800home/Img4.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-img-3{
    background-image: url(../images/800home/Img3.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-img-4{
    background-image: url(../images/800home/Img4.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.w-stars{
    width: 100px;
}

.w-commas{
    width: 50px;
}

.w-90{
    width: 98% !important;
}

.w-profile{
    width: 45px;
}

.rounded-start{
    border-top-left-radius: 50px !important ;
    border-bottom-left-radius: 50px !important ;
}

.text-grey{
    color: #cfcfcf !important;
}

.listing-icons{
    width: 20px;
}

.border-white{
    border-color: #797979 !important;
}

#listing .slick-prev{
    left: 5% ; 
    z-index: 1;
}

#listing .slick-next{
    right: 5% ; 
    z-index: 2;
}

.react-tabs__tab{
    color: #cfcfcf;
    margin-left: 10px;
    margin-right: 10px;
}

.react-tabs__tab--selected{
    opacity: 1;
    color: #cfcfcf !important;
    border: none !important ;
    background-color: transparent !important;
    text-decoration: 2px underline var(--primary-ss-color) ;
    text-underline-offset: 5px;
}

.react-tabs__tab--selected::after{
    background-color: transparent !important;
}

#listing .image-gallery-svg{
    width: 20px !important;
}

.image-gallery-icon:hover{
    color: var(--primary-ss-color) !important;
}

.width-desktop-mobile{
    width: 30%;
}

.image-gallery-image{
    transition: 0.3s !important;
}

#listing2 .image-gallery-image{
    height: 270px !important;
    overflow: hidden !important;
    object-fit: cover;
    object-position: 100%; 
}

#listing2 .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px) !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh) !important;

}

#listing2 .fullscreen .image-gallery-image{
    height: 100% !important;
    overflow: hidden !important;
    object-fit: cover;
    object-position: 100%; 
}

.image-gallery-image:hover{
    transform: scale(1.1) !important;
    transition: all 0.3s ease-in-out !important;
}

.title-color:hover{
    color: var(--primary-ss-color) !important;
}

.common-banner{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 55vh;
}

.banner-title{
    font-size: 3vw;
    padding-top: 25vh;
}

.banner-title-sell-property{
    font-size: 3vw;
    padding-top: 20vh;
}

.shadow-800homes{
    box-shadow: 2px 1px 6px var(--primary-ss-color) !important;
}

.bg-1{
    background-image: url(../images/1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 500px;
}

.bg-2{
    background-image: url(../images/2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 400px;
}

#imagegallery-d-none .image-gallery-play-button{
    display: none;
}

#imagegallery-d-none .image-gallery-fullscreen-button{
    display: none;
}

.sticky-top{
    position: sticky;
    top: 25px ;
}

.border-ss-primary{
    border: 1px solid var(--primary-ss-color) !important;
    background-color: transparent !important;
}

.border-ss-primary-hover{
    border: 1px solid #cfcfcf !important;
    background-color: transparent !important;
}

.border-ss-primary-hover:hover{
    border: 1px solid var(--primary-ss-color) !important;
    background-color: transparent !important;
}

.w-icons{
    width: 50px;
}

.w-blog-image{
    width: 250px;
}

.bg-middle-banner-color{
    background-color: #1f1f1f;
}

.hover1{
    display: none ;
}

.right-menu:hover .hover1{
    display: block;
}

.w-content{
    background-color: white;
    padding-top: 10px ;
    padding-bottom: 10px;
    border-radius: 0px !important;
    color: black !important;
}


.py-ss-2{
    padding-top: 10px ;
    padding-bottom: 8px;
}

.lh-1-7em{
    line-height: 1.7em !important;    
}

.bg-fixed{
    background-attachment: fixed;
}


#cv{
    position: relative;
}

#cv label{
    position: absolute;
    z-index: 10;
    bottom: 0%;
    left: 0%;
    color: #cfcfcf60 !important;
}


.rounded-100{
    border-radius: 100%;
    padding-top: 20px ;
    padding-bottom: 15px ;
    padding-left: 20px ;
    padding-right: 20px ;
}

.hover-icons img{
    opacity: 0.7 ;
}

.hover-icons img:hover{
    opacity: 1 ;
}

.bg-grey{
    background-color: #4a4a4a70;
} 


.align-text-line {
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important; 
    overflow: hidden !important;
    }

    .align-text-line2 {
        display: -webkit-box !important;
        -webkit-line-clamp: 2 !important;
        line-clamp: 2 !important;
        -webkit-box-orient: vertical !important; 
        overflow: hidden !important;
        }


        .align-text-line3 {
            display: -webkit-box !important;
            -webkit-line-clamp: 3 !important;
            line-clamp: 3 !important;
            -webkit-box-orient: vertical !important; 
            overflow: hidden !important;
            }

            
    .w-logo1{
        width: 150px ;
    }

    .w-logo2{
        width: 300px ;
    }

    .w-logo1-mb{
        width: 140px ;
    }

    .w-logo2-mb{
        width: 250px ;
    }

    .w-blogs{
        width: 28%;
    }

    .z-top{
        z-index: 10000 !important;
    }

    .fs-ss-blog{
        padding-top: 24vh;
        font-size: 2.7vw;
    }

    .PhoneInputInput {
        border-bottom: none !important;
    }